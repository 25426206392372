<template>
  <div id="app">
    <router-view v-if="$route.meta.noKeepAlive" />
    <keep-alive v-else>
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
import { commonApi } from "@/utils/api.js";
import { APP_ID } from '@/config';
export default {
  async mounted() {
    const param = {'appId':APP_ID, 'url': window.location.href};
    const res = await commonApi.getJsapiTicket(param);
    console.log(res);
    window.wx.config({
      ...res,
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      // appId: "wx67d99aa823b30aad", // 必填，公众号的唯一标识
      // timestamp: "1618022503738", // 必填，生成签名的时间戳
      // nonceStr: "shidanli", // 必填，生成签名的随机串
      // signature: "b9ce474cdc59e5140bd9cc4fb52c21282392f824", // 必填，签名
      jsApiList: [
        "checkJsApi",
        "startRecord",
        "stopRecord",
        "playVoice",
        "stopVoice",
        "uploadVoice",
        "downloadVoice",
      ], // 必填，需要使用的JS接口列表
    });
    window.wx.error((res) => {
      this.$toast(JSON.stringify(res || {}));
    });
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

html,
body {
  margin: 0;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.van-tabs.van-tabs--line {
  .van-tabs__wrap {
    height: 20px;
  }
  .van-tabs__nav {
    padding-left: 0;
    padding-right: 0;
  }
  .van-tabs__line {
    width: 12px;
    height: 2px;
    background-color: #cd011f;
  }
  .van-tab--active {
    color: #2c2c2c;
    font-weight: 500;
  }
  .van-tab {
    height: 20px;
    font-size: 12px;
    line-height: 17px;
    padding-left: 0;
    padding-right: 0;
    margin-right: 27px;
    flex-shrink: 0;
    flex-grow: 0;
    flex: none;
  }
}

/* 全局样式 */
.mb-12 {
  margin-bottom: 12px;
}
.infinite-loading-container {
  padding-bottom: 8px;
}
.infinite-loading-no-more {
  line-height: 50px;
  color: #999;
}

.fixed-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  z-index: 100;
}
</style>
