import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Toast } from 'vant';
import * as user from './utils/user';
import 'vant/lib/index.css'
import AudioRecorder from 'vue-audio-recorder'
import axios from 'axios';
import permission from './utils/permission'

Vue.config.productionTip = false
Vue.use(Toast);
Vue.use(AudioRecorder)
Vue.directive('permission', permission);

if (process.env.NODE_ENV !== 'development') {
  if (window.location.href.includes('https')) {
    axios.defaults.baseURL = 'https://ebsc.shidanli.cn/prod-api';
  } else {
    axios.defaults.baseURL = 'http://ebsc.shidanli.cn/prod-api';
  }
}
router.beforeEach((to, from, next) => {
  if (!to.meta.noLogin && !user.getToken() && to.name !== 'OrderDownload') {
    next('/login');
  } else {
    next();
  }
});

router.afterEach(function (to) {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
});

window.vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
