
// const ERROR_MSG = ['检索成功', '城市列表', '位置结果未知', '导航结果未知', '非法密钥', '非法请求', '没有权限', '服务不可用', '超时'];
/**
 * 初始化地图
 */
export const initMap = async (eleId, options = {}) => {
  const {
    longitude = 116.404,
    latitude = 39.915,
    zoom = 14,
    currentLocation = false,
    currentLocationMarker = false,
  } = options;

  if (window.BMapGL && eleId) {
    const result = {};
    const map = new window.BMapGL.Map(eleId);
    const point = new window.BMapGL.Point(longitude, latitude);
    map.centerAndZoom(point, zoom);
    map.enableScrollWheelZoom(true);
    result.map = map;

    // 初始化当前位置
    if (currentLocation) {
      result.currentLocation = await initCurrentLocation(map, { zoom });
    }
    // 标记当前位置
    if (currentLocationMarker && result.currentLocation) {
      window.__current_marker__ = addMapMarker({ map, latitude: result.currentLocation.lat, longitude: result.currentLocation.lng });
    }

    return result;
  } else {
    return null;
  }
}

/**
 * 地图定位当前位置
 */
export const initCurrentLocation = (map, options = {}) => {

  // 高德地图定位
  return new Promise((resolve) => {
    const { zoom = 14 } = options;
    if (window.AMap) {
      window.AMap.plugin('AMap.Geolocation', function() {
        var geolocation = new window.AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 10000,
          // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
          buttonOffset: new window.AMap.Pixel(10, 20),
          //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
          zoomToAccuracy: true,     
          //  定位按钮的排放位置,  RB表示右下
          buttonPosition: 'RB'
        })
      
        geolocation.getCurrentPosition()
        window.AMap.event.addListener(geolocation, 'complete', onComplete)
        window.AMap.event.addListener(geolocation, 'error', onError)
      
        function onComplete (data) {
          console.log('current location: ', data)
          map.centerAndZoom(data.position, zoom);
          map.panTo(data.position);
          const addressArr = [
            data.addressComponent.country,
            data.addressComponent.province,
            data.addressComponent.city,
            data.addressComponent.district,
            data.addressComponent.township,
            data.addressComponent.street,
          ].filter(d => d);
          resolve({
            ...data.position,
            address: addressArr.slice(-2).join(''),
          })
        }
      
        function onError (data) {
          console.log("定位失败：", data);
          resolve();
        }
      })
    }
  })

  // 淘汰百度地图
  // return new Promise((resolve) => {
  //   if (window.BMapGL && map) {
  //     const { zoom = 14 } = options;
  //     const geolocation = new window.BMapGL.Geolocation();
  //     geolocation.getCurrentPosition(r => {
  //       if (geolocation.getStatus() == window.BMAP_STATUS_SUCCESS) {
  //         map.centerAndZoom(r.point, zoom);
  //         map.panTo(r.point);
  //         resolve(r.point);
  //       } else {
  //         const status = geolocation.getStatus();
  //         console.log("定位失败：" + ERROR_MSG[status]);
  //         resolve();
  //       }
  //     }, { enableHighAccuracy: true, timeout: 5000 });
  //   }
  // });
}

/**
 * 搜索当前地图中心点
 */
export const searchCurrentCenter = (map) => {
  return new Promise((resolve) => {
    if (window.BMapGL && map) {
      const center = map.getCenter();
      const local = new window.BMapGL.LocalSearch(map, {
        renderOptions: { selectFirstResult: true },
        onSearchComplete: (results) => {
          if (local.getStatus() == window.BMAP_STATUS_SUCCESS) {
            resolve(results._pois);
          } else {
            resolve([]);
          }
        }
      });
      const myGeo = new window.BMapGL.Geocoder();
      myGeo.getLocation(center, function (result) {
        if (result) {
          local.search(result.address);
        } else {
          resolve([]);
        }
      });
    } else {
      resolve([]);
    }
  });
}

/**
 * 经纬度 -> address
 */

export function getCurrentAddress(currentLocation) {
  return new Promise((resolve) => {
    if (window.BMapGL) {
      const myGeo = new window.BMapGL.Geocoder();
      myGeo.getLocation(currentLocation, function (result) {
        if (result) {
          resolve(result)
        } else {
          resolve({});
        }
      });
    } else {
      resolve({});
    }
  });
}

/**
 * 添加点标记
 */
export const addMapMarker = (options = {}) => {
  let { map, longitude = 116.404, latitude = 39.915, marker = null } = options;
  if (window.BMapGL && map) {
    if (!marker) {
      marker = new window.BMapGL.Marker(new window.BMapGL.Point(longitude, latitude));
      map.addOverlay(marker);
    } else {
      marker.setPosition(new window.BMapGL.Point(longitude, latitude));
    }
    return marker;
  }
}

/**
 * 清除所有点标记
 */
export const clearAllMarkers = (map) => {
  if (window.BMapGL && map) {
    const allOverlay = map.getOverlays();
    for(let j = 0; j < allOverlay.length; j++) {
      if (allOverlay[j] !== window.__current_marker__) {
        map.removeOverlay(allOverlay[j]);
      }
    }
  }
}

/**
 * 导航
 * 参数说明：http://lbsyun.baidu.com/index.php?title=uri/api/web
 */
export const mapNavigation = (options = {}) => {
  let {
    originLongitude = '',
    originLatitude = '',
    longitude = '',
    latitude = '',
    title = '',
    content = '',
  } = options;
  if (originLatitude && originLongitude) {
    window.location.href = `http://api.map.baidu.com/direction?origin=latlng:${originLatitude},${originLongitude}|name:我的位置&destination=${latitude},${longitude}&mode=driving&output=html&region=上海`;
  } else {
    window.location.href = `http://api.map.baidu.com/marker?location=${latitude},${longitude}&title=${title}&content=${content}&output=html`;
  }
}

export const getMapCenter = (map) => {
  return map && map.getCenter();
}

/**
 * 播放轨迹动画
 */
export const playMapTrace = (map, path) => {
  if (window.BMapGL && map && path && path.length) {
    const point = [];
    for (let i = 0; i < path.length; i++) {
      point.push(new window.BMapGL.Point(path[i].lng, path[i].lat));
    }
    const pl = new window.BMapGL.Polyline(point);
    const trackAni = new window.BMapGLLib.TrackAnimation(map, pl, {
      overallView: true, // 动画完成后自动调整视野到总览
      tilt: 30,          // 轨迹播放的角度，默认为55
      duration: Math.min(10000, path.length * 1500),   // 动画持续时长，默认为10000，单位ms
      delay: 0        // 动画开始的延迟，默认0，单位ms
    });
    trackAni.start();
    return trackAni;
  }
}


export default {
  initMap,
  playMapTrace,
  getMapCenter,
  addMapMarker,
  clearAllMarkers,
  mapNavigation,
  initCurrentLocation,
  searchCurrentCenter,
  getCurrentAddress,
}